<template>
  <div>
    <!-- Start: Top -->
    <div class="d-flex align-center">
      <div class="text-h5 font-weight-bold">Mega Menu</div>
    </div>
    <!-- End: Top -->
    <!-- Start: Main -->
    <v-row class="menu-row mt-3">
      <!-- Start: Level 1 -->
      <v-col class="menu-col py-0">
        <div class="menu-col--title text-h6 text-center font-weight-bold mb-3">
          Level 1
        </div>
        <div class="menu-col--list">
          <div
            class="list-item"
            :class="
              activeMenus.menu1 && item.id === activeMenus.menu1.id
                ? 'cyan lighten-5 font-weight-bold'
                : null
            "
            v-for="item in menus1"
            :key="item.id"
            @click="getMenus2(item)"
          >
            <div class="list-item--text">{{ item.name }}</div>
          </div>
        </div>
      </v-col>
      <!-- End: Level 1 -->
      <!-- Start: Level 2 -->
      <v-col class="menu-col py-0">
        <div class="menu-col--title text-h6 text-center font-weight-bold mb-3">
          Level 2
        </div>
        <template v-if="activeMenus.menu1">
          <!-- Start: Menu control -->
          <div
            class="menu-col--control"
            :class="
              !addMenuControl.active ||
              (addMenuControl.active && addMenuControl.level !== 2)
                ? 'control-btn'
                : null
            "
            @click="
              !addMenuControl.active ||
              (addMenuControl.active && addMenuControl.level !== 2)
                ? activeAddMenu(2)
                : null
            "
          >
            <div class="top">
              <div class="title text-body-1 font-weight-bold">Thêm mới</div>
              <div
                class="menu-action"
                v-if="addMenuControl.level === 2 && addMenuControl.active"
              >
                <v-btn class="mr-1" icon small @click.stop="addMenu(2, menu2)">
                  <v-icon color="green" size="20px">mdi-check</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="closeAddMenu(2)">
                  <v-icon color="red accent-2" size="20px">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <template
              v-if="addMenuControl.level === 2 && addMenuControl.active"
            >
              <div class="text-error" v-if="addMenuError">
                {{ addMenuError }}
              </div>
              <v-text-field
                v-model="menu2.name"
                class="text-body-1 mt-0 pt-0"
                dense
                flat
                hide-details
                outlined
                placeholder="Nhập tiêu đề"
                solo
                @keypress.enter="addMenu(2, menu2)"
              ></v-text-field>
              <v-text-field
                v-model="menu2.url"
                class="text-body-1 mt-0 pt-0 mt-2"
                dense
                flat
                hide-details
                outlined
                placeholder="Nhập url"
                solo
                @keypress.enter="addMenu(2, menu2)"
              ></v-text-field>
            </template>
          </div>
          <!-- End: Menu control -->
          <!-- Start: List item -->
          <transition-group name="flip-list" tag="div" class="menu-col--list">
            <div
              class="list-item"
              :class="
                activeMenus.menu2 && activeMenus.menu2.id === item.id
                  ? 'cyan lighten-5 font-weight-bold'
                  : null
              "
              v-for="(item, index) in menus2"
              :key="item.id"
              @click="getMenus3(item)"
            >
              <!-- Start: List item top -->
              <div class="list-item--top">
                <div class="list-item--text">
                  <span
                    :class="
                      editedMenu.level === 2 && editedMenu.index === index
                        ? 'text-bold'
                        : null
                    "
                  >
                    {{ item.name }}
                  </span>
                  <span
                    v-if="editedMenu.level === 2 && editedMenu.index === index"
                  >
                    <span style="margin-left: 0.125rem">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="12px"
                        width="12px"
                        viewBox="10 0 100 125"
                        style="enable-background:new 0 0 100 100; transform: rotate(-90deg); fill: #888"
                        xml:space="preserve"
                      >
                        <switch>
                          <g>
                            <path
                              d="M56.9,78.7l38.5-45.7c4.9-5.8,0.8-14.8-6.9-14.8h-77c-7.6,0-11.8,8.9-6.9,14.8l38.5,45.7C46.7,82.9,53.3,82.9,56.9,78.7z"
                            />
                          </g>
                        </switch>
                      </svg>
                    </span>
                    <span>Chỉnh sửa</span>
                  </span>
                </div>
                <div
                  class="menu-action"
                  v-if="editedMenu.level === 2 && editedMenu.index === index"
                >
                  <v-btn
                    class="mr-1"
                    :disabled="item.name.length === 0 || item.url.length === 0"
                    icon
                    small
                    @click="
                      item.name.length > 0 && item.url.length > 0
                        ? saveEditedMenu(item)
                        : null
                    "
                  >
                    <v-icon color="green" size="20px">mdi-check</v-icon>
                  </v-btn>
                  <v-btn icon small @click="cancelEditedMenu(index)">
                    <v-icon color="red accent-2" size="20px">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- End: List item top -->
              <!-- Start: List item form -->
              <div
                class="list-item--form"
                v-if="editedMenu.level === 2 && editedMenu.index === index"
              >
                <v-text-field
                  v-model="item.name"
                  class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  placeholder="Nhập tiêu đề"
                  solo
                ></v-text-field>
                <v-text-field
                  class="text-body-1 mt-0 pt-0 mt-2"
                  dense
                  flat
                  hide-details
                  v-model="item.url"
                  placeholder="Nhập url"
                  solo
                ></v-text-field>
              </div>
              <!-- End: List item form -->
              <!-- Start: List item icons -->
              <div
                class="list-item--icons"
                v-if="
                  editedMenu.index !== index ||
                    (editedMenu.index === index && editedMenu.level !== 2)
                "
              >
                <v-btn
                  v-if="index > 0"
                  icon
                  small
                  @click.stop="moveUpMenu(2, index)"
                >
                  <v-icon color="grey" size="18px">mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="index < menus2.length - 1"
                  icon
                  small
                  @click.stop="moveDownMenu(2, index)"
                >
                  <v-icon color="grey" size="18px">mdi-arrow-down</v-icon>
                </v-btn>
                <v-btn icon small @click="editMenu(item, index)">
                  <v-icon color="grey" size="18px"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
                <v-btn icon small @click.stop="removeMenu(2, index, item)">
                  <v-icon color="grey" size="18px"
                    >mdi-trash-can-outline</v-icon
                  >
                </v-btn>
              </div>
              <!-- End: List item icons -->
            </div>
          </transition-group>
          <!-- End: List item -->
          <!-- Start: Btn group -->
          <div class="btn-group">
            <v-btn
              class="primary rounded-lg mr-3"
              dark
              depressed
              @click="
                updateMenuStatus.level === 2 &&
                updateMenuStatus.val === 'loading'
                  ? null
                  : updateMenus(2, menus2, deletedMenus2)
              "
            >
              {{
                updateMenuStatus.level === 2 &&
                updateMenuStatus.val === "loading"
                  ? "Đang lưu..."
                  : "Lưu"
              }}
            </v-btn>
            <v-btn
              class="grey lighten-2 grey--text text--darken-2 rounded-lg"
              depressed
              @click="cancelUpdateMenu(2)"
            >
              Hủy
            </v-btn>
          </div>
          <!-- End: Btn group -->
        </template>
      </v-col>
      <!-- End: Level 2 -->
      <!-- Start: Level 3 -->
      <v-col class="menu-col py-0">
        <div class="menu-col--title text-h6 text-center font-weight-bold mb-3">
          Level 3
        </div>
        <template v-if="activeMenus.menu2">
          <!-- Start: Menu control -->
          <div
            class="menu-col--control"
            :class="
              !addMenuControl.active ||
              (addMenuControl.active && addMenuControl.level !== 3)
                ? 'control-btn'
                : null
            "
            @click="
              !addMenuControl.active ||
              (addMenuControl.active && addMenuControl.level !== 3)
                ? activeAddMenu(3)
                : null
            "
          >
            <div class="top">
              <div class="title text-body-1 font-weight-bold">Thêm mới</div>
              <div
                class="menu-action"
                v-if="addMenuControl.level === 3 && addMenuControl.active"
              >
                <v-btn class="mr-1" icon small @click.stop="addMenu(3, menu3)">
                  <v-icon color="green" size="20px">mdi-check</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="closeAddMenu(3)">
                  <v-icon color="red accent-2" size="20px">mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <template
              v-if="addMenuControl.level === 3 && addMenuControl.active"
            >
              <div class="text-error" v-if="addMenuError">
                {{ addMenuError }}
              </div>
              <v-text-field
                v-model="menu3.name"
                class="text-body-1 mt-0 pt-0"
                dense
                flat
                hide-details
                outlined
                placeholder="Nhập tiêu đề"
                solo
                @keypress.enter="addMenu(3, menu3)"
              ></v-text-field>
              <v-text-field
                v-model="menu3.url"
                class="text-body-1 mt-0 pt-0 mt-2"
                dense
                flat
                hide-details
                outlined
                placeholder="Nhập url"
                solo
                @keypress.enter="addMenu(3, menu3)"
              ></v-text-field>
            </template>
          </div>
          <!-- End: Menu control -->
          <!-- Start: List item -->
          <transition-group name="flip-list" tag="div" class="menu-col--list">
            <div
              class="list-item"
              v-for="(item, index) in menus3"
              :key="item.id"
            >
              <!-- Start: List item top -->
              <div class="list-item--top">
                <div class="list-item--text">
                  <span
                    :class="
                      editedMenu.level === 3 && editedMenu.index === index
                        ? 'text-bold'
                        : null
                    "
                  >
                    {{ item.name }}
                  </span>
                  <span
                    v-if="editedMenu.level === 3 && editedMenu.index === index"
                  >
                    <span style="margin-left: 0.125rem">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="12px"
                        width="12px"
                        viewBox="10 0 100 125"
                        style="enable-background:new 0 0 100 100; transform: rotate(-90deg); fill: #888"
                        xml:space="preserve"
                      >
                        <switch>
                          <g>
                            <path
                              d="M56.9,78.7l38.5-45.7c4.9-5.8,0.8-14.8-6.9-14.8h-77c-7.6,0-11.8,8.9-6.9,14.8l38.5,45.7C46.7,82.9,53.3,82.9,56.9,78.7z"
                            />
                          </g>
                        </switch>
                      </svg>
                    </span>
                    <span>Chỉnh sửa</span>
                  </span>
                </div>
                <div
                  class="menu-action"
                  v-if="editedMenu.level === 3 && editedMenu.index === index"
                >
                  <v-btn
                    class="mr-1"
                    :disabled="item.name.length === 0 || item.url.length === 0"
                    icon
                    small
                    @click="
                      item.name.length > 0 && item.url.length > 0
                        ? saveEditedMenu(item)
                        : null
                    "
                  >
                    <v-icon color="green" size="20px">mdi-check</v-icon>
                  </v-btn>
                  <v-btn icon small @click="cancelEditedMenu(index)">
                    <v-icon color="red accent-2" size="20px">mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- End: List item top -->
              <!-- Start: List item form -->
              <div
                class="list-item--form"
                v-if="editedMenu.level === 3 && editedMenu.index === index"
              >
                <v-text-field
                  v-model="item.name"
                  class="text-body-1 mt-0 pt-0"
                  dense
                  flat
                  hide-details
                  outlined
                  placeholder="Nhập tiêu đề"
                  solo
                ></v-text-field>
                <v-text-field
                  v-model="item.url"
                  class="text-body-1 mt-0 pt-0 mt-2"
                  dense
                  flat
                  hide-details
                  outlined
                  placeholder="Nhập url"
                ></v-text-field>
              </div>
              <!-- End: List item form -->
              <!-- Start: List item icons -->
              <div
                class="list-item--icons"
                v-if="
                  editedMenu.index !== index ||
                    (editedMenu.index === index && editedMenu.level !== 3)
                "
              >
                <v-btn
                  v-if="index > 0"
                  icon
                  small
                  @click.stop="moveUpMenu(3, index)"
                >
                  <v-icon color="grey" size="18px">mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="index < menus3.length - 1"
                  icon
                  small
                  @click.stop="moveDownMenu(3, index)"
                >
                  <v-icon color="grey" size="18px">mdi-arrow-down</v-icon>
                </v-btn>
                <v-btn icon small @click.stop="editMenu(item, index)">
                  <v-icon color="grey" size="18px"
                    >mdi-square-edit-outline</v-icon
                  >
                </v-btn>
                <v-btn icon small @click.stop="removeMenu(3, index, item)">
                  <v-icon color="grey" size="18px"
                    >mdi-trash-can-outline</v-icon
                  >
                </v-btn>
              </div>
              <!-- End: List item icons -->
            </div>
          </transition-group>
          <!-- End: List item -->
          <!-- Start: Btn group -->
          <div class="btn-group">
            <v-btn
              class="primary rounded-lg mr-3"
              dark
              depressed
              @click="
                updateMenuStatus.level === 3 &&
                updateMenuStatus.val === 'loading'
                  ? null
                  : updateMenus(3, menus3, deletedMenus3)
              "
            >
              {{
                updateMenuStatus.level === 3 &&
                updateMenuStatus.val === "loading"
                  ? "Đang lưu..."
                  : "Lưu"
              }}
            </v-btn>
            <v-btn
              class="grey lighten-2 grey--text text--darken-2 rounded-lg"
              dark
              depressed
              @click="cancelUpdateMenu(3)"
            >
              Hủy
            </v-btn>
          </div>
          <!-- End: Btn group -->
        </template>
      </v-col>
      <!-- End: Level 3 -->
    </v-row>
    <!-- End: Main -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenus: {
        menu1: null,
        menu2: null
      },
      addMenuControl: {
        active: false,
        level: null
      },
      addMenuError: null,
      deletedMenus2: [],
      deletedMenus3: [],
      editedMenu: {
        menu: null,
        index: null
      },
      menus2: [],
      menus3: [],
      menu2: {
        id: null,
        parent_id: null,
        level: 2,
        name: null,
        url: null,
        slot: null
      },
      menu3: {
        id: null,
        parent_id: null,
        level: 3,
        name: null,
        url: null,
        slot: null
      },
      tempEditedMenu: null,
      tempMenus2: null,
      tempMenus3: null,
      updateMenuStatus: {
        level: null,
        val: null
      }
    };
  },

  watch: {
    "menu2.name"(val) {
      if (
        val &&
        val.length > 0 &&
        this.menu2.url &&
        this.menu2.url.length > 0
      ) {
        this.addMenuError = null;
      }
    },
    "menu2.url"(val) {
      if (
        val &&
        val.length > 0 &&
        this.menu2.name &&
        this.menu2.name.length > 0
      ) {
        this.addMenuError = null;
      }
    },
    "menu3.name"(val) {
      if (
        val &&
        val.length > 0 &&
        this.menu3.url &&
        this.menu3.url.length > 0
      ) {
        this.addMenuError = null;
      }
    },
    "menu3.url"(val) {
      if (
        val &&
        val.length > 0 &&
        this.menu3.name &&
        this.menu3.name.length > 0
      ) {
        this.addMenuError = null;
      }
    }
  },

  computed: {
    megaMenuStatusRequest() {
      return this.$store.getters["MEGA_MENU/statusRequest"];
    },
    menus() {
      return this.$store.getters["MEGA_MENU/megaMenus"];
    },
    menus1() {
      const arr = this.menus.filter(item => {
        return item.level === 1;
      });

      return arr.sort(this.sortAscending);
    }
  },

  async created() {
    await this.$store.dispatch("MEGA_MENU/getMegaMenus");
    await this.getMenus2(this.menus1[0]);
    await this.getMenus3(this.menus2[0]);
  },

  methods: {
    activeAddMenu(level) {
      this.addMenuControl.level = level;
      this.addMenuControl.active = true;
    },

    addMenu(level, menu) {
      if (menu.name === null || menu.url === null) {
        this.addMenuError = "Nhập đầy đủ tiêu đề và url để hoàn tất!";
        return;
      }
      if (level === 2) {
        menu.parent_id = this.activeMenus.menu1.id;
        menu.slot = this.menus2.length + 1;
        menu.id =
          "add" +
          Math.random()
            .toString()
            .substr(2, 8);

        this.menus2.push({ ...menu });
        this.resetMenu(2);
      } else if (level === 3) {
        menu.parent_id = this.activeMenus.menu2.id;
        menu.slot = this.menus3.length + 1;
        menu.id =
          "add" +
          Math.random()
            .toString()
            .substr(2, 8);

        this.menus3.push({ ...menu });
        this.resetMenu(3);
      }
    },

    sortAscending(a, b) {
      const attr = "slot";
      let valA = a[attr];
      let valB = b[attr];

      if (valA < valB) {
        return -1;
      }
      if (valA > valB) {
        return 1;
      }
      return 0;
    },

    cancelEditedMenu(index) {
      if (this.editedMenu.level === 2) {
        this.menus2[index] = this.tempEditedMenu;
      } else if (this.editedMenu.level === 3) {
        this.menus3[index] = this.tempEditedMenu;
      }

      // Reset editedMenu
      this.editedMenu.level = null;
      this.editedMenu.menu = null;
      this.editedMenu.index = null;
      this.tempEditedMenu = null;
    },

    cancelUpdateMenu(level) {
      if (level === 2) {
        this.menus2 = JSON.parse(JSON.stringify(this.tempMenus2));
        this.deletedMenus2 = [];
      } else if (level === 3) {
        this.menus3 = JSON.parse(JSON.stringify(this.tempMenus3));
        this.deletedMenus3 = [];
      }
    },

    closeAddMenu(level) {
      this.addMenuControl.level = null;
      this.addMenuControl.active = false;
      this.resetMenu(level);
    },

    editMenu(menu, index) {
      this.tempEditedMenu = { ...menu };
      this.editedMenu.menu = menu;
      this.editedMenu.index = index;
      this.editedMenu.level = menu.level;
    },

    getMenus2(menu) {
      this.activeMenus.menu1 = menu;
      this.activeMenus.menu2 = null;
      this.deletedMenus2 = [];
      this.deletedMenus3 = [];

      const arr = this.menus.filter(item => {
        return item.level === 2 && item.parent_id === menu.id;
      });

      this.menus2 = arr.sort(this.sortAscending);
      this.tempMenus2 = JSON.parse(JSON.stringify(this.menus2));

      // Get menus3
      if (this.menus2.length > 0) {
        this.getMenus3(this.menus2[0]);
      }
    },

    getMenus3(menu) {
      this.activeMenus.menu2 = menu;
      this.deletedMenus3 = [];

      const arr = this.menus.filter(item => {
        return item.level === 3 && item.parent_id === menu.id;
      });

      this.menus3 = arr.sort(this.sortAscending);
      this.tempMenus3 = JSON.parse(JSON.stringify(this.menus3));
    },

    moveDownMenu(level, index) {
      if (level === 2) {
        const tempSlot = this.menus2[index].slot;
        this.menus2[index].slot = this.menus2[index + 1].slot;
        this.menus2[index + 1].slot = tempSlot;

        const temp = this.menus2[index];
        this.menus2[index] = this.menus2[index + 1];
        this.menus2[index + 1] = temp;

        this.menus2 = [...this.menus2];
      } else if (level === 3) {
        const tempSlot = this.menus3[index].slot;
        this.menus3[index].slot = this.menus3[index + 1].slot;
        this.menus3[index + 1].slot = tempSlot;

        const temp = this.menus3[index];
        this.menus3[index] = this.menus3[index + 1];
        this.menus3[index + 1] = temp;

        this.menus3 = [...this.menus3];
      }
    },

    moveUpMenu(level, index) {
      if (level === 2) {
        const tempSlot = this.menus2[index].slot;
        this.menus2[index].slot = this.menus2[index - 1].slot;
        this.menus2[index - 1].slot = tempSlot;

        const temp = this.menus2[index];
        this.menus2[index] = this.menus2[index - 1];
        this.menus2[index - 1] = temp;

        this.menus2 = [...this.menus2];
      } else if (level === 3) {
        const tempSlot = this.menus3[index].slot;
        this.menus3[index].slot = this.menus3[index - 1].slot;
        this.menus3[index - 1].slot = tempSlot;

        const temp = this.menus3[index];
        this.menus3[index] = this.menus3[index - 1];
        this.menus3[index - 1] = temp;

        this.menus3 = [...this.menus3];
      }
    },

    resetMenu(level) {
      if (level === 2) {
        this.menu2.name = null;
        this.menu2.url = null;
      } else if (level === 3) {
        this.menu3.name = null;
        this.menu3.url = null;
      }
    },

    removeMenu(level, index, menu) {
      if (level === 2) {
        // Push to deletedMenus
        if (menu.id.toString().slice(0, 3) !== "add") {
          this.deletedMenus2.push(menu.id);
        }

        this.menus2.splice(index, 1);

        // Update slot
        this.menus2 = this.menus2.map((item, index) => {
          return {
            id: item.id,
            parent_id: item.parent_id,
            level: item.level,
            name: item.name,
            url: item.url,
            slot: index + 1
          };
        });
      } else if (level === 3) {
        // Push to deletedMenus
        if (menu.id.toString().slice(0, 3) !== "add") {
          this.deletedMenus3.push(menu.id);
        }

        this.menus3.splice(index, 1);
        // Update slot
        this.menus3 = this.menus3.map((item, index) => {
          return {
            id: item.id,
            parent_id: item.parent_id,
            level: item.level,
            name: item.name,
            url: item.url,
            slot: index + 1
          };
        });
      }
    },

    saveEditedMenu(menu) {
      if (menu.name === null || menu.url === null) {
        // this.editMenuError = "Nhập đầy đủ tiêu đề và url để hoàn tất!";
        return;
      }
      this.editedMenu.level = null;
      this.editedMenu.menu = null;
      this.editedMenu.index = null;
      this.tempEditedMenu = null;
    },

    async updateMenus(level, menus, deletedArr) {
      this.updateMenuStatus.level = level;
      this.updateMenuStatus.val = "loading";
      // Update slot
      menus = menus.map((item, index) => {
        return {
          id: item.id,
          parent_id: item.parent_id,
          level: item.level,
          name: item.name,
          url: item.url,
          slot: index + 1
        };
      });

      await this.$store.dispatch("MEGA_MENU/updateMegaMenus", {
        add: menus.filter(item => {
          return item.id.toString().slice(0, 3) === "add";
        }),
        edit: menus.filter(item => {
          return item.id.toString().slice(0, 3) !== "add";
        }),
        delete: deletedArr
      });

      if (this.megaMenuStatusRequest.value === "success-updateMegaMenus") {
        // Get latest data
        await this.$store.dispatch("MEGA_MENU/getMegaMenus");
        await this.getMenus2(this.activeMenus.menu1);
        await this.getMenus3(this.activeMenus.menu2);

        // Update temp menus
        if (level === 2) {
          this.tempMenus2 = JSON.parse(JSON.stringify(menus));
          this.deletedMenus2 = [];
        } else if (level === 3) {
          this.tempMenus3 = JSON.parse(JSON.stringify(menus));
          this.deletedMenus3 = [];
        }

        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });

        this.updateMenuStatus.val = "success";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  &-col {
    border-left: 1px solid #e5e5e5;
    &:first-child {
      border-left: 0;
    }
    &--control {
      background-color: #ffffff;
      border-radius: 0.25rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
      padding: 0.75rem;
      transition: all 0.4s ease;
      &.control-btn {
        cursor: pointer;
        transition: all 0.4s ease;
        &:hover {
          background-color: #27bece;
          .title {
            color: #ffffff;
          }
        }
        .top {
          justify-content: center;
          margin-bottom: 0;
        }
        .title {
          color: #1aaebf;
        }
      }
      .top {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;
      }
      .title {
        transition: all 0.4s ease;
      }
    }
    &--list {
      .list-item {
        background-color: #ffffff;
        border-radius: 0.25rem;
        cursor: pointer;
        margin: 0.5rem 0;
        padding: 0.75rem;
        position: relative;
        transition: all 0.4s ease;
        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
          .list-item--icons {
            display: flex;
          }
        }
        &--top {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
        &--form {
          margin-top: 0.25rem;
        }
        &--icons {
          align-items: center;
          display: none;
          height: 36px;
          padding: 0 0.25rem;
          position: absolute;
          right: 0;
          top: 3px;
          transition: all 0.4s ease;
          .icon {
            align-items: center;
            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
            display: flex;
            justify-content: center;
            margin-left: 0.5rem;
            height: 22px;
            width: 22px;
            &:hover {
              .icon-delete {
                color: #ff6a6a;
              }
              svg {
                fill: #1aaebf;
              }
              .icon-edit {
                svg {
                  fill: #1aaebf;
                  stroke: #1aaebf;
                }
              }
            }
            .icon-delete {
              color: #999999;
            }
            .icon-edit {
              svg {
                stroke: #999999;
                stroke-width: 2;
              }
            }
            svg {
              fill: #999999;
              transition: all 0.4s ease;
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }
  &-input {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 0.25rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0.75rem;
    outline: 0;
    width: 100%;
    &::placeholder {
      color: #cccccc;
    }
  }
  &-action {
    display: flex;
    justify-content: flex-end;
  }
  &-btn {
    align-items: center;
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 28px;
    transition: all 0.4s ease;
    width: 28px;
    &:hover {
      background-color: #f2f2f2;
    }
    svg {
      vertical-align: bottom;
    }
    &--success {
      margin-right: 0.5rem;
      &.disabled {
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
        svg {
          fill: #999999;
        }
      }
      svg {
        fill: #4e994e;
      }
    }
    &--danger {
      svg {
        fill: #f75454;
      }
    }
  }
}

.btn-group {
  display: flex;
  margin-top: 1rem;
  transition: all 0.4s ease;
}
.btn-info {
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-danger {
  background-color: #f75454 !important;
  border: 0;
  border-radius: 0.25rem !important;
  height: 38px;
  margin-left: 0.75rem;
  outline: 0;
  &:hover {
    background-color: #e74141 !important;
  }
}

.text-bold {
  font-weight: 600;
}

.text-error {
  color: #ff6a6a;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.flip-list-move {
  transition: transform 1s;
}
.flip-list-enter-active,
.flip-list-leave-active {
  transition: all 1s;
}
.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
